var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.isActiveFilter,"label":"Is active","value":_vm.is_active,"item-text":"label","item-value":"value"},on:{"change":(is_active) => _vm.changeFilter({ is_active })}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.isBannedFilter,"label":"Is banned","value":_vm.is_banned,"item-text":"label","item-value":"value"},on:{"change":(is_banned) => _vm.changeFilter({ is_banned })}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.users_list,"loading":_vm.isLoadingUser,"search-input":_vm.searchUser,"item-text":"display_name","label":"User","placeholder":"Search","clearable":"","return-object":""},on:{"update:searchInput":function($event){_vm.searchUser=$event},"update:search-input":function($event){_vm.searchUser=$event},"focus":() => _vm.onLoadUsers()},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1)],1),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary"},on:{"click":_vm.toggleOpenCreateDomain}},[_vm._v(" Create Domain ")])],1)]),_c('v-card-text',{key:_vm.keyTable},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"hide-default-footer":"","loading":_vm.loading,"itemsPerPage":+_vm.pageFilter.limit,"disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"header.name",fn:function({ header }){return [_vm._v(" "+_vm._s(header.text.toUpperCase())+" ")]}},_vm._l((_vm.headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )),function(header){return {key:`item.${header.value}`,fn:function({ header, value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.is_active",fn:function({ item }){return [_c('div',{staticClass:"d-inline-flex"},[_c('v-switch',{staticStyle:{"margin":"0"},attrs:{"hide-details":"","inputValue":item.is_active,"loading":_vm.isEditing && _vm.status.editing_domain === item.id
                ? 'warning'
                : false,"disabled":_vm.isEditing},on:{"change":(e) => _vm.onEdit(item.id, { is_active: e })}})],1)]}},{key:"item.user",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-items-center"},[(item.user)?_c('span',[_vm._v(_vm._s(item.user.username))]):_vm._e()])]}},{key:"item.max_links",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{attrs:{"title":"Current links"}},[_vm._v(_vm._s(item.total_links))]),_vm._v("/"),_c('span',{attrs:{"title":"Max links"}},[_vm._v(_vm._s(item.usage_limit))]),_c('edit',{attrs:{"domain":item},on:{"onEdit":(value) => _vm.onEdit(item.id, value)}})],1)]}},{key:"item.is_banned",fn:function({ item }){return [_c('span',[(item.is_banned)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-close")])],1)]}}],null,true)}),(_vm.list && _vm.list.length)?_c('CustomPagination',{attrs:{"count":_vm.count,"limit":_vm.pageFilter.limit,"offset":_vm.pageFilter.offset,"changeOffset":(value) => {
          _vm.changeFilter({ offset: value });
        }}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }